import React, { useMemo } from 'react'
import { useTable } from 'react-table'

import { formatDollarAmount } from '../../lib/helpers'
import { Table, Header, HeaderCell, TextCell, NarrowCell } from '../common/Table'

const defaultColumn: any = {
  TextCell,
}

interface Props {
  data: any[]
}

export default function TotalByPracticeTable(props: Props) {
  const { data } = props

  const columns = useMemo(
    () => [
      {
        Header: 'Practice',
        accessor: 'practice',
      },
      {
        Header: '# of Consults',
        accessor: 'numberOfConsults',
      },
      {
        Header: 'Amount (USD)',
        accessor: (s: any) => `$${formatDollarAmount(s.amount)}`,
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
    defaultColumn,
  })

  return (
    <div className="border rounded p-4 mb-4">
      <h5 className="bold">Overview by Practice</h5>

      <Table className="w-100" cellSpacing={0} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, idx: number) => (
                <HeaderCell key={idx} {...column.getHeaderProps()}>
                  <Header>{column.render('Header')}</Header>
                </HeaderCell>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any, idx: number) => {
                  return (
                    <NarrowCell key={idx} {...cell.getCellProps()}>
                      {cell.render('TextCell')}
                    </NarrowCell>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

import React from 'react'
import chunk from 'lodash/chunk'

import { LineItem } from '../../interfaces/LineItem'

// @ts-ignore
import logo from '../../lib/images/logo.png'

const removeEmojis = (str: string) => {
  const emojiRegex = /([\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{2300}-\u{23FF}]|[\u{2B50}-\u{2BFF}]|[\u{1F900}-\u{1F9FF}]|[\u{1F000}-\u{1F02F}])/gu
  return str.replace(emojiRegex, '')
}

interface Props {
  lineItems: LineItem[]
  enterpriseName?: string
  period: string
  totalByPractice: TotalByPractice[]
}

export interface TotalByPractice {
  practice: string
  amount: number
  numberOfConsults: number
}

export default function DownloadableLineItemsPdf(props: Props) {
  const maxLineItemsForFirstPage = 57
  const maxLineItemsForSubsequentPages = 65

  const firstPageLineItems = props.lineItems.slice(0, maxLineItemsForFirstPage)
  const subsequentPagesLineItems = props.lineItems.slice(maxLineItemsForFirstPage)
  const subsequentPagesLineItemsChunks = chunk(subsequentPagesLineItems, maxLineItemsForSubsequentPages)

  const lineItemsTable = (lineItems: LineItem[], isFirstPage = false) => (
    <div key={lineItems[0].description} className="page-section p-5">
      <table className="text-s w-100">
        {isFirstPage && (
          <tr className="bold">
            <td>Date</td>
            <td>Description</td>
            <td>Practice</td>
            <td>Amount</td>
          </tr>
        )}
        <tbody>
          {lineItems.map((l, idx) => (
            <tr key={idx}>
              <td>{l.date}</td>
              <td>{removeEmojis(l.description)}</td>
              <td>{l.practice}</td>
              <td>{l.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  const totalByPracticeTable = (
    <div className="page-section p-5">
      <h1 className="text-xxl semibold mb-5">Overview By Practice</h1>

      <table className="text-s w-100">
        <tbody>
          <tr className="bold">
            <td>Practice</td>
            <td># of Consults</td>
            <td>Total</td>
          </tr>
          {props.totalByPractice.map((t, idx) => (
            <tr key={idx}>
              <td>{t.practice}</td>
              <td>{t.numberOfConsults}</td>
              <td>${t.amount.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  if (!props.lineItems.length) return null

  return (
    <div id="hiddenContent" style={{ position: 'absolute', left: '-10000px', top: '0px', width: '1100px' }}>
      <div className="page-section p-5">
        <div className="d-flex align-items-start justify-content-between w-100">
          <h1 className="text-xxl semibold m-0">Invoice Details</h1>

          <div className="flex-center gap-5px">
            <img style={{ height: '22px', width: 'auto' }} src={logo} />
            <h1 className="bold m-0 text-xl">Radimal</h1>
          </div>
        </div>

        <div className="text-s mt-4">
          <p className="m-0">
            Invoice details for <span className="bold">{props.enterpriseName}</span>
          </p>
          <p className="m-0">{props.period}</p>
        </div>
      </div>

      {lineItemsTable(firstPageLineItems, true)}
      {subsequentPagesLineItemsChunks.map((chunk) => lineItemsTable(chunk))}
      {totalByPracticeTable}
    </div>
  )
}
